import React, { Component } from 'react'

export default class Header extends Component {
    render() {
        return (
            <div>
                <div className="topbar is-sticky">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <ul className="topbar-nav d-lg-none">
                                <li className="topbar-nav-item relative">
                                    <a className="toggle-nav" href="#">
                                        <div className="toggle-icon">
                                            <span className="toggle-line" />
                                            <span className="toggle-line" />
                                            <span className="toggle-line" />
                                            <span className="toggle-line" />
                                        </div>
                                    </a>
                                </li>{/* .topbar-nav-item */}
                            </ul>{/* .topbar-nav */}
                            <a className="topbar-logo" href="./">
                                <img src="images/logo-light2x.png" srcSet="images/logo-light2x.png 2x" alt="logo" />
                            </a>
                            <ul className="topbar-nav">
                                <li className="topbar-nav-item relative">
                                    <span className="user-welcome d-none d-lg-inline-block">Welcome! Stefan Harary</span>
                                    <a className="toggle-tigger user-thumb" href="#"><em className="ti ti-user" /></a>
                                    <div className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown">
                                        <div className="user-status">
                                            <h6 className="user-status-title">Token balance</h6>
                                            <div className="user-status-balance">12,000,000 <small>TWZ</small></div>
                                        </div>
                                        <ul className="user-links">
                                            <li><a href="profile.html"><i className="ti ti-id-badge" />My Profile</a></li>
                                            <li><a href="#"><i className="ti ti-infinite" />Referral</a></li>
                                            <li><a href="activity.html"><i className="ti ti-eye" />Activity</a></li>
                                        </ul>
                                        <ul className="user-links bg-light">
                                            <li><a href="sign-in.html"><i className="ti ti-power-off" />Logout</a></li>
                                        </ul>
                                    </div>
                                </li>{/* .topbar-nav-item */}
                            </ul>{/* .topbar-nav */}
                        </div>
                    </div>{/* .container */}
                </div>{/* .topbar */}

            </div>

        )
    }
}
